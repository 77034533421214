<template>
  <div>
    <v-container class="container--fluid text-center">
      <v-card class="mt-5">
        <v-card-title class=" ">
          <span class="blue--text text-h6"> Sensör Ekle </span>
        </v-card-title>
        <v-divider />

        <v-card-text>
          <div class="col-12 d-flex">
            <v-btn
              class="mt-2"
              outlined
              color="indigo"
              dense
              @click="createDevice"
            >
              <v-icon left>
                mdi-plus
              </v-icon>
              Sensor Ekle
            </v-btn>
            <div class="col-2 pt-1">
              <v-select
                v-model="santralModel"
                outlined
                dense
                :items="santralList"
                hide-details
                label="santral list"
              />
            </div>
          </div>

          <v-data-table
            :headers="devheaders"
            :items="devdesserts"
            :items-per-page.sync="itemPerPage"
            class="elevation-1"
            dense
            :sort-by="['santral', 'SENSORNAME', 'SENSOR', 'sensorStatus']"
          >
            <template v-slot:item.sensorStatus="{item}">
              <Chips
                :payload="{
                  color: item.sensorStatus === 'Active' ? 'green' : 'red',
                  text: item.sensorStatus === 'Active' ? 'Aktif' : 'Pasif',
                  textColor: 'white',
                  small: true
                }"
              />
            </template>
            <template v-slot:item.SENSOR="{item}">
              <v-icon

                class="mr-2"
                @click="editItemOpen(item)"
              >
                mdi-pencil-circle
              </v-icon>
              <v-icon

                @click="deleteItem(item)"
              >
                mdi-delete-circle
              </v-icon>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>

      <v-dialog
        v-model="dialog"
        persistent
        max-width="1000px"
      >
        <v-card class="col-12 mt-5 pt-0 pb-0">
          <v-card-title>
            <span class="blue--text"> <h2>Cihaz Ekle</h2></span>
          </v-card-title>
          <v-card-text>
            <Sensor
              :sensordata="sensordata"
              :santral-list="santralList"
              :santral-data="santralData"
              @sensorlist="getSensor_List"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              text
              dense
              outlined
              @click="dialog = false"
            >
              Kapat
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import { getDeviceList } from '@/api/Request/farm';
import { delSensor, getSensorList } from '@/api/Request/sensor';
import { getSantralList } from '@/api/Request/santral';
import Sensor from '@/views/components/Station/Sensor/Add.vue';

export default {
  components: {
    Sensor,
  },
  props: [],
  data: () => ({
    devheaders: [],
    devdesserts: [],
    itemPerPage: 15,
    dialog: false,
    search: '',
    valid: true,
    lazy: true,

    edit: false,
    editdialog: false,

    prefix: '',
    santralCode: '',

    dataloggerComponentName: '',
    dataloggerModel: '',
    dataloggerList: [],
    dataloggerData: {},
    dataloggerComponentList: {},
    santralList: [],
    santralModel: null,
    sensordata: {},
    santralData: {},
  }),
  watch: {
    dataloggerModel() {
      this.dataloggerComponentName = this.dataloggerComponentList[this.dataloggerModel];
    },
    santralModel() {
      this.getSensor_List();
    },
    // eslint-disable-next-line func-names
    '$route.params.prefix': function (search) {
      this.prefix = search;
      this.getSantralList();
    },
    // eslint-disable-next-line func-names
    '$route.params.companyCode': function (search) {
      this.companyCode = search;
    },
  },
  created() {},
  mounted() {
    this.dataloggerComponentList = {
      Sunguard1: 'SunguardBusiness3',
      Sunguard2: 'SunguardBusiness2',
      Huawei1: 'HuaweiSmartLogger3000',
      Danfass: 'Danfoss1',
    };

    const storage = window.localStorage;
    const userCompany = JSON.parse(window.atob(storage.getItem('user_company')));
    this.companyCode = userCompany.companyCode;
    this.prefix = userCompany.prefix;

    this.getSantralList();
  },

  methods: {
    setStatus(item) {
      return item === 'Active' ? 'Aktif' : 'Pasif';
    },
    getSantralList() {
      const params1 = {
        condiniton: { prefix: this.prefix },
      };

      getSantralList(params1).then((res) => {
        const santralList = [
          {
            text: 'Seçiniz',
            value: null,
          },
        ];
        const santralData = {};

        Object.keys(res.data.data).forEach((k) => {
          santralData[res.data.data[k].santralCode] = res.data.data[k];
          santralList.push({
            text: res.data.data[k].santral,
            value: res.data.data[k].santralCode,
          });
        });
        this.santralData = santralData;
        this.santralList = santralList;
        this.getSensor_List();
      });
    },
    deleteItem(item) {
      const param = {
        condiniton: {
          santral: item.santral,
          SENSOR: item.SENSOR,
          SENSORNAME: item.SENSORNAME,
          prefix: this.prefix,
        },
      };
      delSensor(param).then(() => {
        this.getSensor_List();
      });
    },
    editItemOpen(item) {
      this.dialog = true;
      this.sensordata = item;
    },

    createDevice() {
      this.dialog = true;
      this.sensordata = {};
    },
    santralAdd(item) {
      this.deviceModel = item.dataLoggerType;
    },

    // Datalogger listesi çek
    getDevice() {
      const params = {
        condiniton: {
          DeviceType: 'Datalogger',
        },
      };
      getDeviceList(params).then((res) => {
        const devList = [];
        const deviceData = {};
        Object.keys(res.data.data).forEach((i) => {
          devList.push({
            text: `${res.data.data[i].DeviceManufacture} ${res.data.data[i].DeviceModel}`,
            value: res.data.data[i].Model,
          });
          deviceData[res.data.data[i].Model] = res.data.data[i];
        });
        this.dataloggerList = devList;

        this.dataloggerData = deviceData;
      });
    },

    getSensor_List() {
      this.devheaders = [
        { text: 'santral', value: 'santral' },
        { text: 'Sensor adı', value: 'SENSORNAME' },
        { text: 'ID', value: 'ID' },
        { text: 'Seri Nuamarsı', value: 'sensorDeviceSerial' },
        { text: 'SENSOR', value: 'SENSOR' },
        { text: 'Status', value: 'sensorStatus' },
      ];
      const params = {
        condiniton: {
          prefix: this.prefix,
        },
      };
      if (this.santralModel in this.santralData) {
        params.condiniton.santral = this.santralData[this.santralModel].santral;
      }

      getSensorList(params).then((res) => {
        this.devdesserts = res.data.data;
      });
    },
  },
};
</script>

<style>
#userForm .col.col-12 {
  padding: 0;
}
</style>
