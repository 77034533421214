<template>
  <v-stepper v-model="e1">
    <v-stepper-header>
      <v-stepper-step
        :complete="e1 > 1"
        step="1"
      >
        Datalogger
      </v-stepper-step>

      <v-divider />

      <v-stepper-step
        :complete="e1 > 2"
        step="2"
      >
        Cihaz tipi
      </v-stepper-step>

      <v-divider />

      <v-stepper-step step="3">
        Veri
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <v-row
          class="pt-4"
          align="center"
        >
          <v-col
            cols="12"
            sm="6"
          >
            <v-select
              v-model="santralModel"
              outlined
              dense
              :items="santralList"
              :menu-props="{maxHeight: '300'}"
              label="santral"
              hide-details
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-select
              v-model="dataloggerModel"
              outlined
              dense
              :items="dataloggerList"
              :menu-props="{maxHeight: '300'}"
              label="Datalogger"
              hide-details
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-select
              v-model="sensorTypeModel"
              :items="sensorTypeList"
              :menu-props="{maxHeight: '300'}"
              label="Cihaz Listesi"
              outlined
              dense
              hide-details
            />
          </v-col>
        </v-row>
        <v-col>
          <v-btn
            color="primary"
            dense
            outlined
            @click="e1 = 2"
          >
            Sonraki
          </v-btn>
        </v-col>
      </v-stepper-content>
      <v-stepper-content step="2">
        <v-row
          class="pt-4"
          align="center"
        >
          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="formdata.sensorDeviceSerial"
              hide-details
              label="Seri numarası"
              outlined
              dense
            />
          </v-col>
          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="formdata.SENSORNAME"
              hide-details
              label="Sensör adı"
              outlined
              dense
            />
          </v-col>
          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="formdata.ID"
              hide-details
              label="ID"
              outlined
              dense
            />
          </v-col>

          <!--   <v-col cols="12" sm="3">
            <v-text-field
              v-model="formdata.SENSOR"
              label="SENSOR"
              outlined
              dense

            />
          </v-col> -->
        </v-row>
        <v-row class="pt-4">
          <v-col
            v-if="formdata.dynamicKey"
            cols="12"
            md="4"
          >
            <v-select
              v-model="formdata.dynamicKey.Irradiation"
              outlined
              dense
              :items="IrradiationList"
              :menu-props="{maxHeight: '300'}"
              label="Işınım Parametreleri"
              hide-details
              multiple
            />
          </v-col>
          <v-col
            v-if="formdata.dynamicKey.SensorTemp"
            cols="12"
            md="4"
          >
            <v-select
              v-model="formdata.dynamicKey.SensorTemp"
              :items="SensorTemp"
              :menu-props="{maxHeight: '300'}"
              label="Sıcaklık Parametreleri"
              outlined
              dense
              hide-details
              multiple
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class=" ml-4  mr-5 mt-0 pt-0"
          >
            <v-switch
              v-model="sensorStatus"
              label="Durum"
              hide-details
              inset
            />
          </v-col>
        </v-row>

        <v-col cols="12">
          <v-btn
            color="primary"
            dense
            outlined
            @click="e1 = 3"
          >
            Sonraki
          </v-btn>
        </v-col>
      </v-stepper-content>

      <v-stepper-content step="3">
        <v-row class="mb-0 mt-0 pt-0 pb-0">
          <v-col cols="12">
            <v-data-table
              v-model="deviceParameterModel"
              :headers="devheaders"
              :items="deviceParameterList"
              class="elevation-1"
              dense
              item-key="value"
              show-select
              :items-per-page="itemPerPage"
            />
          </v-col>

          <v-col
            cols="12"
            class="d-flex"
          >
            <v-col class="col-2">
              <v-btn
                dense
                outlined
                @click="setSensorCreate"
              >
                Kaydet
              </v-btn>
            </v-col>
            <v-col class="col-2">
              <v-btn
                color="primary"
                dense
                outlined
                @click="e1 = 1"
              >
                Sonraki
              </v-btn>
            </v-col>
          </v-col>
        </v-row>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import { getDeviceList } from '@/api/Request/farm';
import { getDataloggerList } from '@/api/Request/datalogger';
import { setSensor } from '@/api/Request/sensor';
import { getInverterTypeList } from '@/api/Request/inverter';

import helper from '@/api/helper';

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['sensordata', 'santralData', 'santralList'],
  data: () => ({
    e1: 1,
    deviceTypeModel: 'SENSOR',
    sensorTypeModel: '',
    sensorTypeList: [],
    inverterTypeData: [],
    itemPerPage: 10,
    deviceParameterList: [],
    deviceParamHeader: [],
    deviceParameterModel: [],
    dataloggerList: [],
    devheaders: [],
    dataloggerModel: '',
    deviceList: [],
    deviceModel: '',
    dataloggerData: [],
    SensorTemp: [],
    IrradiationList: [],
    santralModel: '',
    sensorStatus: true,

    formdata: {},
  }),
  watch: {
    sensorTypeModel() {
      this.getInverterType();
    },
    santralModel() {
      if (this.santralList.length > 0) {
        this.formdata.santralCode = this.santralData[this.santralModel].santralCode;
        this.formdata.santral = this.santralData[this.santralModel].santral;
      }

      this.getDatalogger();
    },
    sensordata() {
      this.setNewMeasure();
      this.santralModel = '';
      this.dataloggerModel = '';
      this.sensorTypeModel = 0;
      this.santralData = {};
      this.deviceParameterList = [];
      this.deviceParameterModel = [];
      this.e1 = 1;
      this.deviceTypeModel = 'SENSOR';
      this.deviceModel = '';
      this.setSensorDataFunc();
    },
  },
  mounted() {
    const storage = window.localStorage;
    const userCompany = JSON.parse(window.atob(storage.getItem('user_company')));
    this.companyCode = userCompany.companyCode;
    this.prefix = userCompany.prefix;
    this.setNewMeasure();
    this.getSensorTypeList();
    this.setSensorDataFunc();
  },
  methods: {
    setNewMeasure() {
      this.formdata = {
        santralCode: '',
        santral: '',
        sensorDeviceSerial: '',
        SENSORMODEL: '',
        ID: '',
        SENSORNAME: '',
        SENSOR: '',
        sensorDeviceModel: '',
        sensorManufacture: '',
        sensorStatus: '',
        dynamicKey: {
          Irradiation: [],
          SensorTemp: [],
        },
      };
    },
    setSensorDataFunc() {
      this.IrradiationList = [];
      this.PanelTempList = [];
      if (this.sensordata.general_measure_list !== undefined) {
        this.devheaders = [
          { text: 'text', value: 'text' },
          { text: 'key', value: 'key' },
          { text: 'unit', value: 'unit' },
          { text: 'type', value: 'type' },
          { text: 'formuletype', value: 'formuletype' },
        ];
        this.deviceParameterList = this.sensordata.general_measure_list;
        this.deviceParameterModel = this.sensordata.general_measure_list;
        this.sensorTypeModel = this.sensordata.sensorTypeDeviceId;
        this.santralModel = this.sensordata.santralCode;

        this.formdata = { ...this.formdata, ...this.sensordata };
        this.sensorStatus = this.sensordata.sensorStatus === 'Active';
        this.dataloggerModel = this.sensordata.dataloggerName;
        this.setSelectedMeasure();
      }
    },
    setSelectedMeasure() {
      const measure = this.deviceParameterList;
      this.IrradiationList = [];
      this.SensorTemp = [];

      Object.keys(measure).forEach((key) => {
        if (measure[key].key.indexOf('Irr') !== -1) {
          this.IrradiationList.push(measure[key].key);
        }
        if (measure[key].key.indexOf('Temp') !== -1) {
          this.SensorTemp.push(measure[key].key);
        }
      });
    },
    // datalogger listesi
    getDatalogger() {
      const params = {
        condiniton: {
          prefix: this.prefix,
          santral: this.santralData[this.santralModel].santral,
        },
      };

      getDataloggerList(params).then((res) => {
        const devList = [];
        const deviceList = {};

        Object.keys(res.data.data).forEach((i) => {
          deviceList[res.data.data[i].deviceName] = res.data.data[i];
          devList.push({
            text: `${res.data.data[i].deviceName}`,
            value: `${res.data.data[i].deviceName}`,
          });
        });

        this.dataloggerData = deviceList;
        this.dataloggerList = devList;
      });
    },

    ///  inverter tipleri listesi
    getSensorTypeList() {
      const params = {
        condiniton: {
          DeviceType: this.deviceTypeModel,
        },
      };

      getDeviceList(params).then((res) => {
        const devList = [];
        const deviceData = {};
        this.deviceData = res.data.data;
        Object.keys(res.data.data).forEach((i) => {
          const dev = res.data.data[i].DeviceId;
          deviceData[dev] = res.data.data[i];
          devList.push({
            text: `${res.data.data[i].DeviceManufacture} ${res.data.data[i].DeviceModel}`,
            value: res.data.data[i].DeviceId,
          });
        });

        this.deviceData = deviceData;
        this.sensorTypeList = devList;
      });
    },

    getInverterType() {
      this.devheaders = [
        { text: 'text', value: 'value' },
        { text: 'key', value: 'key' },
        { text: 'unit', value: 'unit' },
        { text: 'type', value: 'type' },
        { text: 'formuletype', value: 'formuletype' },
      ];

      if (helper.is_Empty(this.dataloggerModel)) {
        return;
      }

      if (helper.is_Empty(this.deviceData)) {
        return;
      }

      const params = {
        condiniton: {
          SENSORMODEL: this.deviceData[this.sensorTypeModel].Model,
        },
      };

      if (this.dataloggerData[this.dataloggerModel] !== undefined) {
        const temp = this.dataloggerData[this.dataloggerModel];
        params.condiniton.Datalogger = temp.Model;
      }

      getInverterTypeList(params).then((res) => {
        this.deviceParameterList = res.data.data[0].general_measure_list;
        this.deviceParameterModel = res.data.data[0].general_measure_list;

        this.itemPerPage = this.deviceParameterList.length;
        this.setSelectedMeasure();
      });
    },

    setSensorCreate() {
      // eslint-disable-next-line radix

      const temp = this.dataloggerData[this.dataloggerModel];
      this.formdata.SENSOR = this.formdata.ID < 10 ? `SENSOR0${this.formdata.ID}` : `SENSOR${this.formdata.ID}`;

      const data = {
        SENSORNAME: this.formdata.SENSORNAME,
        ID: this.formdata.ID,
        SENSOR: this.formdata.SENSOR,

        sensorStatus: this.sensorStatus === true ? 'Active' : 'Passive',
        SENSORMODEL: this.deviceData[this.sensorTypeModel].Model,
        sensorManufacture: this.deviceData[this.sensorTypeModel].DeviceManufacture,
        sensorDeviceModel: this.deviceData[this.sensorTypeModel].DeviceModel,
        sensorTypeDeviceId: this.deviceData[this.sensorTypeModel].DeviceId,

        sensorDeviceSerial: this.formdata.sensorDeviceSerial,
        general_measure_list: this.deviceParameterModel,

        dynamicKey: {
          Irradiation: '',
          SensorTemp: '',
        },
        dataloggerName: temp.deviceName,
        dataloggerDeviceModel: temp.DeviceModel,
        dataloggerTypeDeviceId: temp.DeviceId,
        santral: temp.santral,
        santralCode: temp.santralCode,
        timeInterval: temp.timeInterval,
      };

      if ('dynamicKey' in this.formdata) {
        if ('Irradiation' in this.formdata.dynamicKey) {
          data.dynamicKey = this.formdata.dynamicKey.Irradiation;
        }
        if ('SensorTemp' in this.formdata.dynamicKey) {
          data.SensorTemp = this.formdata.dynamicKey.SensorTemp;
        }
      }

      const params = {
        condiniton: {
          companyCode: this.companyCode,
          prefix: this.prefix,
          deviceType: this.deviceTypeModel,
          SENSOR: data.SENSOR,
          santral: data.santral,
          santralCode: data.santralCode,
        },
        data,
      };

      setSensor(params).then(() => {
        // console.log(res);
        this.$emit('sensorlist');
      });
    },
  },
};
</script>
